import { useEffect, useRef, useState } from "react";
import { EDIT_SVG } from "../../../../../utils/SVG";
import { ButtonIconStyled } from "../../../../PushPage/PushPageList/styled";
import { HrefDomenStyled } from "./ListRowFixWidth/styled";
import { ListRowItemStyled } from "./styled";
import DeletePwa from "./DeletePwa/DeletePwa";

type App = {
  id: string;
  name: string;
  category_id: string;
  domen: string;
  push_set_id: string;
  teg: string;
  create: string;
  tarif: string;
  clicks: string;
  install: string;
  uskl: string;
  reg: string;
  dep: string;
  editPwa: string;
  deletePwa: string;
};

type Nam = {
  id: number;
  name: keyof App;
  title: string;
  style: {
    width: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
  styleItem: {
    [key: string]: any;
  };
  render?: (...args: any[]) => {};
};

export const listNames: Nam[] = [
  {
    id: 1,
    name: 'id',
    title: 'ID',
    style: {
      width: '90px',
      paddingLeft: '36px',
    },
    styleItem: {
      width: '90px',
      paddingLeft: '36px',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      position: 'relative',
      cursor: 'pointer',
    },
  },
  {
    id: 2,
    name: 'name',
    title: 'Название',
    style: {
      width: '123px',
    },
    styleItem: {
      width: '123px',
    },
  },
  {
    id: 3,
    name: 'category_id',
    title: 'Категория',
    style: {
      width: '118px',
    },
    styleItem: {
      width: '118px',
    },
  },
  {
    id: 4,
    name: 'domen',
    title: 'Домен',
    style: {
      width: '140px',
    },
    styleItem: {
      width: '140px',
    },
    render: function (pwa) {
      return (
          <ListRowItemStyled css={this.styleItem}>
            <HrefDomenStyled
              href={`https://${pwa[this.name]}/${pwa.id}?sub1=SUB1&sub2=SUB2&sub3=SUB3&sub4=SUB4&sub5=SUB5&sub6=SUB6&sub7=SUB7&sub8=SUB8&sub9=SUB9&sub10=SUB10`}
              target="_blank"
            >
              {pwa[this.name]}
            </HrefDomenStyled>
          </ListRowItemStyled>
      )
    }
  },
  {
    id: 5,
    name: 'push_set_id',
    title: 'PushSet',
    style: {
      width: '140px',
    },
    styleItem: {
      width: '140px',
    },
  },
  {
    id: 6,
    name: 'teg',
    title: 'Теги',
    style: {
      width: '123.47px',
    },
    styleItem: {
      width: '123.47px',
    },
  },
  {
    id: 7,
    name: 'create',
    title: 'Создано',
    style: {
      width: '100px',
    },
    styleItem: {
      width: '100px',
    },
  },
  {
    id: 8,
    name: 'tarif',
    title: 'Тариф',
    style: {
      width: '70px',
    },
    styleItem: {
      width: '70px',
    },
  },
  {
    id: 9,
    name: 'clicks',
    title: 'Клики',
    style: {
      width: '69px',
    },
    styleItem: {
      width: '69px',
      'text-align': 'center',
      'padding-left': '0 !important',
    },
  },
  {
    id: 10,
    name: 'install',
    title: 'Установки',
    style: {
      width: '100px',
    },
    styleItem: {
      width: '100px',
      'text-align': 'center',
      'padding-left': '0 !important',
    },
  },
  {
    id: 11,
    name: 'uskl',
    title: 'УС/КЛ',
    style: {
      width: '70px',
    },
    styleItem: {
      width: '70px',
      'text-align': 'center',
      'padding-left': '0 !important',
    },
  },
  {
    id: 12,
    name: 'reg',
    title: 'Рег',
    style: {
      width: '50px',
    },
    styleItem: {
      width: '50px',
      'text-align': 'center',
      'padding-left': '0 !important',
    },
  },
  {
    id: 13,
    name: 'dep',
    title: 'Dep',
    style: {
      width: '50px',
    },
    styleItem: {
      width: '50px',
      'text-align': 'center',
      'padding-left': '0 !important',
    },
  },
  {
    id: 14,
    name: 'editPwa',
    title: '',
    style: {
      width: '40px',
    },
    styleItem: {
      width: '40px',
    },
    render: function (pwa, funcClick) {
      const { id, styleItem } = this;
  
      return (
        <ListRowItemStyled css={styleItem} key={id}>
          <ButtonIconStyled onClick={() => funcClick(pwa.id)}>
            <EDIT_SVG />
          </ButtonIconStyled>
        </ListRowItemStyled>
      );
    }
  },
  {
    id: 15,
    name: 'deletePwa',
    title: '',
    style: {
      width: '40px',
    },
    styleItem: {
      width: '40px',
    },
    render: function (pwa) {
      const { id, styleItem } = this;
  
      return <DeletePwa keyId={id} styled={styleItem} pwaId={pwa.id}/>;
    }
  }
];