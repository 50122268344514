import NavPWA from "./NavPWA/NavPWA";
import ListPWA from "./ListPWA/ListPWA";
import { TablesPWAStyled } from "./styled";
import { usePWAList } from "../../../../hooks/usePWAList";
import { useEffect, useState } from "react";

const TablesPWA = () => {
  const {isError, isLoading, forceUpdate} = usePWAList();

  return (
    <TablesPWAStyled>
      <NavPWA forceUpdate={forceUpdate} />
      <ListPWA isLoading={isLoading} isError={isError} />
    </TablesPWAStyled>
  );
};

export default TablesPWA;
