import {
  ContainerStyled,
  ListNameItemStyled,
  ListNamesStyled,
  ListRowContainerStyled,
  ListRowStyled,
  ListStyled,
  LoadingStyled,
} from "./styled";
import { listNames } from "./ListNamesAPI";
import {
  useAppSelector,
} from "../../../../../hooks/hooksStore";
import React from "react";
import ListRowItem from "./ListRowFixWidth/ListRowFixWidth";
import { useEditPage } from "../../../../../hooks/useEditPage";
import { SpinerStyled } from "../../../../../components/ButtonItem/styled";

type Props = {
  isLoading: boolean;
  isError: boolean;
};

const ListPWA: React.FC<Props> = ({ isLoading, isError }) => {
  const { appFilterList, appFilterListArchive } = useAppSelector((state) => state.pwalist);
  const { isArchive } = useAppSelector(state => state.core);
  const { handleEditItem } = useEditPage();

  const appUseList = isArchive ? appFilterListArchive : appFilterList;

  return (
    <ContainerStyled>
      <ListStyled>
        <ListNamesStyled>
          {listNames.map((nam) => (
            <ListNameItemStyled css={nam.style} key={nam.id}>
              {nam.title}
            </ListNameItemStyled>
          ))}
        </ListNamesStyled>

        <ListRowContainerStyled>
          {isLoading && (
            <LoadingStyled>
              <SpinerStyled variant="blue"/>
            </LoadingStyled>
          )}

          {isError && !isLoading && !appUseList.length && <div>Error Download Items</div>}

          {!!appUseList.length && !isLoading &&
            appUseList.map((pwa) => (
              <ListRowStyled key={pwa.id}>
                {listNames.map((nam) => (
                  <>
                    {!nam.render ? (
                      <ListRowItem
                        text={pwa[nam.name]?.toString() || ''}
                        css={nam.styleItem}
                        name={nam.name}
                        pwaId={pwa.id}
                      />
                    ) : nam.render(pwa, handleEditItem)}
                  </>
                ))}
              </ListRowStyled>
            ))}
        </ListRowContainerStyled>
      </ListStyled>
    </ContainerStyled>
  );
};

export default ListPWA;
