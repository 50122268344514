import { toast } from "react-toastify";
import { deletePwa } from "../../../../../../api/requestService";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooksStore";
import { RED_DELETE_SVG } from "../../../../../../utils/SVG";
import { ButtonIconStyled } from "../../../../../PushPage/PushPageList/styled";
import { ListRowItemStyled } from "../styled";
import { usePWAList } from "../../../../../../hooks/usePWAList";
import { getUserPermision } from "../../../../../../utils/func";

type Props = {
  keyId: number;
  styled: {[key: string]: any};
  pwaId: string
};

const DeletePwa: React.FC<Props> = ({
  keyId,
  styled,
  pwaId,
}) => {
  const userPermision = getUserPermision('deletePwa');

  const handleDelete = async (id: string) => {
    try {
      await deletePwa(id);
      toast.success('PWA удалено успешно!');
      usePWAList();
    } catch {
      toast.error('Ошибка удаления PWA!')
    };
  };

  return (
    <ListRowItemStyled css={styled} key={keyId}>
      <ButtonIconStyled onClick={() => userPermision && handleDelete(pwaId)} userPermision={userPermision}>
        <RED_DELETE_SVG fill={userPermision ? "#FF6B6C" : "#ff6b6b32"} />
      </ButtonIconStyled>
    </ListRowItemStyled>
  );
};

export default DeletePwa;